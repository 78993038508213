import {initReducer, templateCRUD} from "../base";
import {REDUCERS} from "../../types";
import {
  ENUM_ORDER_DELIVERY_STATUS,
  ENUM_ORDER_TYPES,
} from "../../../constants/enums";

const templateOrders = {
  ...templateCRUD,
  params: {
    ...templateCRUD.params,
    orderType: {
      id: "%",
      name: "Select Order Type",
    },
    orderStatus: {
      id: "%",
      name: "Select Order Status",
    },
    orderOrigin: {
      id: '%',
      name: "Select Order Origin",
    },
    orderDriver: {
      id: '%',
      name: "Select Order Driver",
    },
    date: new Date(),
  },
  selectedOrderPhotos: [],
}

// vendor admin
export const vendor_w_vendor_admin = initReducer(
  REDUCERS.vendor_w_vendor_admin,
  {}
);
export const vendor_twilioAccount_w_vendor_admin = initReducer(
  REDUCERS.vendor_twilioAccount_w_vendor_admin,
  {}
);
export const vendor_twilioSubAccount_w_vendor_admin = initReducer(
  REDUCERS.vendor_twilioSubAccount_w_vendor_admin,
  {}
);
export const vendor_smsServiceSetting_w_vendor_admin = initReducer(
  REDUCERS.vendor_smsServiceSetting_w_vendor_admin,
  {}
);
export const drivers_w_vendor_admin = initReducer(
  REDUCERS.drivers_w_vendor_admin,
  templateCRUD
);
export const admins_w_vendor_admin = initReducer(
  REDUCERS.admins_w_vendor_admin,
  templateCRUD
);
export const messageTemplates_w_vendor_admin = initReducer(
  REDUCERS.messageTemplates_w_vendor_admin,
  templateCRUD
);
export const orders_w_vendor_admin = initReducer(
  REDUCERS.orders_w_vendor_admin,
  {
    ...templateOrders
  }
);
export const socket_w_vendor_admin = initReducer(
  REDUCERS.socket_w_vendor_admin,
  {
    // orders
    ordersAll: [],
    completedOrders: [],
    ordersIssueOnParser: [],

    ordersDeliveryAll: [],
    ordersDeliveryAssigned: [],
    ordersDeliveryNonAssigned: [],

    ordersPickupAll: [],
    ordersPickup: [],

    // drivers
    driversAll: [],
    driversOnline: [],
    driversInactive: [],
    driversOffline: [],

    // processing params
    showSidebarProcessing: false,
    ordersProcessing: [],
    selectedOrdersProcessing: [],
    selectedOrder: {},
    params: {
      q: "",
      orderType: {id: ENUM_ORDER_TYPES.delivery, label: "delivery"},
      orderStatus: {
        id: ENUM_ORDER_DELIVERY_STATUS.processing,
        label: "processing",
      },
      orderOrigin: {
        id: '%',
        label: "All",
      },
    },

    // chats
    // driver chat
    driversChat: [],
    selectedDriver: {},
    selectedDriverChat: [],
    openDriverChat: false,

    // customer chat
    customersChat: [],
    selectedCustomer: {},
    selectedCustomerChat: [],
    openCustomerChat: false,

    allSMS: {},
    customers: [],
    totalUnReadCountCutomerChat: 0,
  }
);
export const customers_w_vendor_admin = initReducer(
  REDUCERS.customers_w_vendor_admin,
  templateCRUD
);
export const customer_notes_w_vendor_admin = initReducer(
  REDUCERS.customer_notes_w_vendor_admin,
  templateCRUD
);
export const customer_orders_w_vendor_admin = initReducer(
  REDUCERS.customer_orders_w_vendor_admin,
  {
    ...templateOrders
  }
);
export const analytics_w_vendor_admin = initReducer(
  REDUCERS.analytics_w_vendor_admin,
  {
    customerCount: 0,
    ordersTotal: 0,
    ordersDelivery: 0,
    ordersPickup: 0,
    ordersTotalRevenue: 0,
  }
);
export const automatedMessages_w_vendor_admin = initReducer(
  REDUCERS.automatedMessages_w_vendor_admin,
  {
    ...templateCRUD,
    params: {
      ...templateCRUD.params,
      status: {
        value: "%",
        label: "Select Status",
        number: 0,
      },
    },
  }
);
export const automatedCustomSms_w_vendor_admin = initReducer(
  REDUCERS.automatedCustomSms_w_vendor_admin,
  templateCRUD
);
export const mails_w_vendor_admin = initReducer(REDUCERS.mails_w_vendor_admin, {
  ...templateCRUD,
  params: {
    ...templateCRUD.params,
    perPage: 7,
  },
});
export const everywhere_w_vendor_admin = initReducer(
  REDUCERS.everywhere_w_vendor_admin,
  {
    showAddOrder: false,
  }
);
export const ondemandSMS_w_vendor_admin = initReducer(
  REDUCERS.ondemandSMS_w_vendor_admin,
  {
    defaultMessage: {},
    customers: [],

    selectedCustomers: [],
  }
);
export const abilities_w_vendor_admin = initReducer(
  REDUCERS.abilities_w_vendor_admin,
  {
    abilities: [],
  }
);
export const selfSmsPermissions_w_vendor_admin = initReducer(
  REDUCERS.selfSmsPermissions_w_vendor_admin,
  {
    selfSmsPermissions: [],
    allSmsPermissions: [],
  }
);
