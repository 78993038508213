import {Slack, Edit2, ShoppingBag, Truck, Users} from "react-feather";
import {USER_TYPES} from "./types";

export const getRoleIconById = (roleId) => {
  if (roleId === USER_TYPES.mvendor_admin)
    return {
      className: "text-success",
      Icon: ShoppingBag,
    };

  return {
    className: "text-info",
    Icon: Users,
  };
};

// TODO enum controller for here
export const getRoleNameById = (roleId) => {
  var name = "";
  Object.keys(USER_TYPES).forEach((roleKey) => {
    if (USER_TYPES[roleKey] === roleId) name = roleKey;
  });
  return name;
};

export const ENUM_STATUS = {
  active: 1,
  inactive: 2,
  banned: 3,
  removed: 4,
};

export const ENUM_OS = {
  ios: 1,
  android: 2,
};

// order enums
export const ENUM_ORDER_TYPES = {
  pickup: 1,
  delivery: 2,
  unidentified: 3,
};

export const ENUM_ORDER_PICKUP_STATUS = {
  // TODO
  processing: 1,
  ready_for_pickup: 2,
  picked_up: 3,
};
export const ENUM_ORDER_DELIVERY_STATUS = {
  // TODO
  processing: 4,
  on_the_way: 5,
  navigated: 7,
  delivered: 6,
};
// end order enums

export const CHAT_MESSAGE_TYPES = {
  text: 1,
  emoji: 2,
  file: 3,
  image: 4,
  link: 5,
};

export const ENUM_NOTIFICATION_TYPES = {
  alert: 1,
  message: 2,
};

export const SMS_SERVICE_TYPES = {
  mVendorTwilioAccount: 1,
  vendorTwilioAccount: 2,
  vendorTwilioSubAccount: 3,
};

export const MAIL_PARSER_INTEGRATIONS = {
  grubhub: {
    id: 1,
    name: "Grubhub",
    email: "orders@eat.grubhub.com",
  },
  toast: {
    id: 2,
    name: "Toast",
    email: "no-reply@toasttab.com",
  },
  wix: {
    id: 3,
    name: "Wix",
    email: "noreply@wixrestaurants.com",
  },
  incentivio: {
    id: 4,
    name: "Incentivio",
    email: "order@client.incentivio.com",
  },
};