import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import createDebounce from "redux-debounced";
import rootReducer from "./reducers/rootReducer";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { createLogger } from "redux-logger";

const persistConfig = {
  key: "root0ssssssssss",

  storage,

  whitelist: ["app", "auth", "user"],
  blacklist: [],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// const logger = createLogger();

// ** init middleware
const middleware = [thunk, createDebounce()];

// ** Dev Tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  persistedReducer,
  // applyMiddleware(thunk, logger)
  composeEnhancers(applyMiddleware(...middleware))
);

let persistor = persistStore(store);
export { store, persistor };
