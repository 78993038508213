// ** Redux Imports
import {combineReducers} from "redux";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

// ** Reducers Imports
import navbar from "./navbar";
import layout from "./layout";

// shared
import {REDUCERS} from "../types";
import {initReducer} from "./base";
import {
  users_w_admin,
  merchantSmsPermissions_w_admin,
  vendors_w_admin,
  vendor_s_drivers_w_admin,
  vendor_s_admins_w_admin,
  integrations_w_admin,
  automatedMessages_w_admin,
} from "./admin";
import {
  mvendors_w_merchant,
  mvendor_s_admins_w_merchant,
  selfSmsPermissions_w_merchant,
  mVendorSmsPermissions_w_merchant,
  mvendor_s_vendors_w_merchant,
  twilio_w_merchant,
} from "./merchant";
import {
  mvendor_w_mvendor_admin,
  mvendor_twilioAccount_w_mvendor_admin,
  vendors_w_mvendor_admin,
  vendor_s_admins_w_mvendor_admin,
  vendor_s_twilioAccount_w_mvendor_admin,
  vendor_s_twilioSubAccount_w_mvendor_admin,
  vendor_s_smsServiceSetting_w_mvendor_admin,
  selfSmsPermissions_w_mvendor_admin,
  vendorSmsPermissions_w_mvendor_admin,
} from "./mvendorAdmin";
import {
  vendor_w_vendor_admin,
  vendor_twilioAccount_w_vendor_admin,
  vendor_twilioSubAccount_w_vendor_admin,
  vendor_smsServiceSetting_w_vendor_admin,
  drivers_w_vendor_admin,
  admins_w_vendor_admin,
  messageTemplates_w_vendor_admin,
  orders_w_vendor_admin,
  socket_w_vendor_admin,
  customers_w_vendor_admin,
  customer_notes_w_vendor_admin,
  customer_orders_w_vendor_admin,
  analytics_w_vendor_admin,
  automatedMessages_w_vendor_admin,
  automatedCustomSms_w_vendor_admin,
  mails_w_vendor_admin,
  everywhere_w_vendor_admin,
  ondemandSMS_w_vendor_admin,
  abilities_w_vendor_admin,
  selfSmsPermissions_w_vendor_admin,
} from "./vendorAdmin";

const status = initReducer(REDUCERS.status, {});
const auth = initReducer(REDUCERS.auth, {});
const data_address = initReducer(REDUCERS.data_address, {
  countries: [],
  regions: [],
});

// shared
const profile = initReducer(REDUCERS.profile, {});

const appReducer = combineReducers({
  status,
  auth,
  data_address,

  navbar,
  layout,

  // admin
  users_w_admin,
  merchantSmsPermissions_w_admin,
  vendors_w_admin,
  vendor_s_drivers_w_admin,
  vendor_s_admins_w_admin,
  integrations_w_admin,
  automatedMessages_w_admin,

  // merchant
  mvendors_w_merchant,
  mvendor_s_admins_w_merchant,
  selfSmsPermissions_w_merchant,
  mVendorSmsPermissions_w_merchant,
  mvendor_s_vendors_w_merchant,
  twilio_w_merchant,

  // multi vendor admin
  mvendor_w_mvendor_admin,
  mvendor_twilioAccount_w_mvendor_admin,
  vendors_w_mvendor_admin,
  vendor_s_admins_w_mvendor_admin,
  vendor_s_twilioAccount_w_mvendor_admin,
  vendor_s_twilioSubAccount_w_mvendor_admin,
  vendor_s_smsServiceSetting_w_mvendor_admin,
  selfSmsPermissions_w_mvendor_admin,
  vendorSmsPermissions_w_mvendor_admin,

  // vendor admin
  vendor_w_vendor_admin,
  vendor_twilioAccount_w_vendor_admin,
  vendor_twilioSubAccount_w_vendor_admin,
  vendor_smsServiceSetting_w_vendor_admin,
  abilities_w_vendor_admin,
  admins_w_vendor_admin,
  drivers_w_vendor_admin,
  messageTemplates_w_vendor_admin,
  orders_w_vendor_admin,
  socket_w_vendor_admin,
  ondemandSMS_w_vendor_admin,
  customers_w_vendor_admin,
  customer_notes_w_vendor_admin,
  customer_orders_w_vendor_admin,
  automatedMessages_w_vendor_admin,
  automatedCustomSms_w_vendor_admin,
  analytics_w_vendor_admin,
  mails_w_vendor_admin,
  everywhere_w_vendor_admin,
  selfSmsPermissions_w_vendor_admin,

  // shared
  profile,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    console.log('a', action.type)
    Object.keys(state).forEach(key => {
      storage.removeItem(`persist:${key}`);
    });
    state = undefined;
  }
  return appReducer(state, action);
}

export default rootReducer;
