import * as LAYOUT from "./layout";
import * as NAVBAR from "./navbar";

export {LAYOUT, NAVBAR};

// redux constants

// base
export const RESET_REDUCER = (stateKey) => `${stateKey}_RESET_REDUCER`;
export const CHANGE_REDUCER = (stateKey) => `${stateKey}_CHANGE_REDUCER`;

// reducers
export const REDUCERS = {
  status: "status",
  auth: "auth",
  data_address: "data_address",

  // shared
  profile: "profile",
  account: "account",

  // admin
  users_w_admin: "users_w_admin",
  merchantSmsPermissions_w_admin: "merchantSmsPermissions_w_admin",
  vendors_w_admin: "vendors_w_admin",
  vendor_s_drivers_w_admin: "vendor_s_drivers_w_admin",
  vendor_s_admins_w_admin: "vendor_s_admins_w_admin",
  integrations_w_admin: "integrations_w_admin",
  automatedMessages_w_admin: "automatedMessages_w_admin",

  // merchant
  mvendors_w_merchant: "mvendors_w_merchant",
  selfSmsPermissions_w_merchant: "selfSmsPermissions_w_merchant",
  mVendorSmsPermissions_w_merchant: "mVendorSmsPermissions_w_merchant",
  mvendor_s_admins_w_merchant: "mvendor_s_admins_w_merchant",
  mvendor_s_vendors_w_merchant: "mvendor_s_vendors_w_merchant",
  twilio_w_merchant: "twilio_w_merchant",

  // multi vendor admin
  mvendor_w_mvendor_admin: "mvendor_w_mvendor_admin",
  mvendor_twilioAccount_w_mvendor_admin: "mvendor_twilioAccount_w_mvendor_admin",
  vendors_w_mvendor_admin: "vendors_w_mvendor_admin",
  vendor_s_admins_w_mvendor_admin: "vendor_s_admins_w_mvendor_admin",
  vendor_s_twilioSubAccount_w_mvendor_admin: "vendor_s_twilioSubAccount_w_mvendor_admin",
  vendor_s_twilioAccount_w_mvendor_admin: "vendor_s_twilioAccount_w_mvendor_admin",
  vendor_s_smsServiceSetting_w_mvendor_admin: "vendor_s_smsServiceSetting_w_mvendor_admin",
  selfSmsPermissions_w_mvendor_admin: "selfSmsPermissions_w_mvendor_admin",
  vendorSmsPermissions_w_mvendor_admin: "vendorSmsPermissions_w_mvendor_admin",

  // vendor admin
  vendor_twilioAccount_w_vendor_admin: "vendor_twilioAccount_w_vendor_admin",
  vendor_twilioSubAccount_w_vendor_admin: "vendor_twilioSubAccount_w_vendor_admin",
  vendor_smsServiceSetting_w_vendor_admin: "vendor_smsServiceSetting_w_vendor_admin",
  abilities_w_vendor_admin: "abilities_w_vendor_admin",
  mails_w_vendor_admin: "mails_w_vendor_admin",
  analytics_w_vendor_admin: "analytics_w_vendor_admin",
  vendor_w_vendor_admin: "vendor_w_vendor_admin",
  admins_w_vendor_admin: "admins_w_vendor_admin",
  drivers_w_vendor_admin: "drivers_w_vendor_admin",
  messageTemplates_w_vendor_admin: "messageTemplates_w_vendor_admin",
  automatedMessages_w_vendor_admin: "automatedMessages_w_vendor_admin",
  automatedCustomSms_w_vendor_admin: "automatedCustomSms_w_vendor_admin",
  orders_w_vendor_admin: "orders_w_vendor_admin",
  socket_w_vendor_admin: "socket_w_vendor_admin",
  customers_w_vendor_admin: "customers_w_vendor_admin",
  customer_notes_w_vendor_admin: "customer_notes_w_vendor_admin",
  customer_orders_w_vendor_admin: "customer_orders_w_vendor_admin",
  everywhere_w_vendor_admin: "everywhere_w_vendor_admin",
  ondemandSMS_w_vendor_admin: "ondemandSMS_w_vendor_admin",
  selfSmsPermissions_w_vendor_admin: "selfSmsPermissions_w_vendor_admin",
};
