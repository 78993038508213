import { initReducer, templateCRUD } from "../base";
import { REDUCERS } from "../../types";

export const users_w_admin = initReducer(REDUCERS.users_w_admin, {
  ...templateCRUD,
  params: {
    ...templateCRUD.params,
    userType: {
      value: "",
      label: "Select User Type",
    },
  },
});
export const merchantSmsPermissions_w_admin = initReducer(
  REDUCERS.merchantSmsPermissions_w_admin,
  {
    smsPermissions: [],
  }
);
export const vendors_w_admin = initReducer(
  REDUCERS.vendors_w_admin,
  templateCRUD
);
export const vendor_s_drivers_w_admin = initReducer(
  REDUCERS.vendor_s_drivers_w_admin,
  templateCRUD
);
export const vendor_s_admins_w_admin = initReducer(
  REDUCERS.vendor_s_admins_w_admin,
  templateCRUD
);
export const integrations_w_admin = initReducer(
  REDUCERS.integrations_w_admin,
  templateCRUD
);
export const automatedMessages_w_admin = initReducer(
  REDUCERS.automatedMessages_w_admin,
  templateCRUD
);
