export const USER_TYPES = {
	site_owner: 1,
	merchant: 2,
	mvendor_admin: 3,
	vendor_admin: 4,
	driver: 5,
};

export const USER_STATUS = {
	ACTIVE: "active",
	INACTIVE: "inactive",
	BANNED: "banned",
};

export const DEFAULT_ORDER_STATUS = "Processing";

export const DELIVERY_ORDER_STATUS = {
	Processing: "Processing",
	"On The Way": "On The Way",
	Delivered: "Delivered",
};

export const PICKUP_ORDER_STATUS = {
	Processing: "Processing",
	"Ready For Pick Up": "Ready For Pick Up",
	"Picked Up": "Picked Up",
};

export const INTEGRATIONS = {
	EMAIL_PARSER: "email-parser",
	WC: "WC",
};

export const TIMES = [
	{
		name: "5 min",
		id: 5,
	},
	{
		name: "10 min",
		id: 10,
	},
	{
		name: "15 min",
		id: 15,
	},
	{
		name: "20 min",
		id: 20,
	},
	{
		name: "25 min",
		id: 25,
	},
	{
		name: "30 min",
		id: 30,
	},
	{
		name: "35 min",
		id: 35,
	},
	{
		name: "40 min",
		id: 40,
	},
	{
		name: "45 min",
		id: 45,
	},
	{
		name: "50 min",
		id: 50,
	},
	{
		name: "55 min",
		id: 55,
	},
	{
		name: "60 min",
		id: 60,
	},
	{
		name: "90 min",
		id: 90,
	},
];

export const TIME_ZONES = [
	{
		title: "Washington, DC (GMT-4)",
		value: -4,
	},
	{
		title: "Chicago (GMT-5)",
		value: -5,
	},
	{
		title: "Denver (GMT-6)",
		value: -6,
	},
	{
		title: "Los Angeles (GMT-7)",
		value: -7,
	},
	{
		title: "Anchorage (GMT-8)",
		value: -8,
	},
	{
		title: "Honolulu (GMT-10)",
		value: -10,
	},
];

export const TWILIO_WEBHOOK_URL = "https://api.centrigoo.com/api/v1/hooks/twilio/vendor/sms"
