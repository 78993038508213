import { initReducer, templateCRUD, templateTwilioAccount } from "../base";
import { REDUCERS } from "../../types";

// multi vendor admin
export const mvendor_w_mvendor_admin = initReducer(
	REDUCERS.mvendor_w_mvendor_admin,
	{}
);
export const mvendor_twilioAccount_w_mvendor_admin = initReducer(
	REDUCERS.mvendor_twilioAccount_w_mvendor_admin,
	templateTwilioAccount,
);
export const vendors_w_mvendor_admin = initReducer(
	REDUCERS.vendors_w_mvendor_admin,
	templateCRUD
);
export const vendor_s_admins_w_mvendor_admin = initReducer(
	REDUCERS.vendor_s_admins_w_mvendor_admin,
	templateCRUD
);
export const vendor_s_twilioAccount_w_mvendor_admin = initReducer(
	REDUCERS.vendor_s_twilioAccount_w_mvendor_admin,
	{},
);
export const vendor_s_twilioSubAccount_w_mvendor_admin = initReducer(
	REDUCERS.vendor_s_twilioSubAccount_w_mvendor_admin,
	{},
);
export const vendor_s_smsServiceSetting_w_mvendor_admin = initReducer(
	REDUCERS.vendor_s_smsServiceSetting_w_mvendor_admin,
	{},
);
export const selfSmsPermissions_w_mvendor_admin = initReducer(
	REDUCERS.selfSmsPermissions_w_mvendor_admin,
	{
		smsPermissions: [],
	}
);
export const vendorSmsPermissions_w_mvendor_admin = initReducer(
	REDUCERS.vendorSmsPermissions_w_mvendor_admin,
	{
		smsPermissions: [],
	}
);
