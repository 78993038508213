import { initReducer, templateCRUD } from "../base";
import { REDUCERS } from "../../types";

// merchant
export const mvendors_w_merchant = initReducer(
  REDUCERS.mvendors_w_merchant,
  templateCRUD
);
export const mvendor_s_admins_w_merchant = initReducer(
  REDUCERS.mvendor_s_admins_w_merchant,
  templateCRUD
);
export const selfSmsPermissions_w_merchant = initReducer(
  REDUCERS.selfSmsPermissions_w_merchant,
  {
    smsPermissions: [],
  }
);
export const mVendorSmsPermissions_w_merchant = initReducer(
  REDUCERS.mVendorSmsPermissions_w_merchant,
  {
    smsPermissions: [],
  }
);
export const mvendor_s_vendors_w_merchant = initReducer(
  REDUCERS.mvendor_s_vendors_w_merchant,
  templateCRUD
);
export const twilio_w_merchant = initReducer(REDUCERS.twilio_w_merchant, {
  numbers: [],
});
