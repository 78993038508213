import { ENUM_STATUS } from "../../constants/enums";
import { RESET_REDUCER, CHANGE_REDUCER } from "../types";

export const initReducer =
	(stateKey, initialState = {}) =>
		(state = initialState, { type, ...rest }) => {
			switch (type) {
				case RESET_REDUCER(stateKey):
					return {
						...initialState,
						...rest,
					};
				case CHANGE_REDUCER(stateKey):
					return {
						...state,
						...rest,
					};
				default:
					return state;
			}
		};

export const templateCRUD = {
	allData: [],
	items: [],
	total: 1,
	params: {
		page: 0,
		perPage: 15,
		status: {
			value: ENUM_STATUS.active,
			label: "Select Status",
			number: 0,
		},
		q: "",
	},
	selectedItem: null,
};


export const templateTwilioAccount = {
	accountSid: "",
	authToken: "",
	basicAuthToken: "",
	addressSid: "",
}